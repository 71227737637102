<template>
    <q-dialog v-model="openDialog">
        <q-card  style="width: 800px; max-width: 80vw;">
            <q-card-section class="row items-center ">
                <div v-if="id === null" class="text-h6">Создать</div>
                <div v-else class="text-h6">Изменить</div>
                <q-space />
                <q-btn icon="close" flat round dense @click="$emit('close')" />
            </q-card-section>
                <q-form
                    @submit="onSubmit"
                >
                    <q-tabs
                        v-model="tab"
                        dense
                        align="justify"
                        :breakpoint="0"
                        class="bg-grey-2"
                    >
                        <q-tab class="text-primary" name="user" label="Фактически использует" />
                        <q-tab class="text-red" name="admin" label="Админ" />
                    </q-tabs>

                    <q-separator />

                    <q-tab-panels v-model="tab" animated>
                        <q-tab-panel name="user">
                             <div>
                                <div class="column">
                                    <q-input outlined v-model="model.name" label="ФИО" dense required hint=""/>
                                    <div class="row q-col-gutter-x-md">
                                        <q-input outlined v-model="model.inn" label="ИНН" dense hint="" class="col-grow col-md-6"/>
                                        <q-input outlined v-model="model.ogrn" label="ОГРН" dense hint="" class="col-grow col-md-6"/>
                                    </div>
                                    <q-input outlined v-model="model.address" label="Адрес" dense hint=""/>
                                    <q-input outlined v-model="model.phone" label="Телефон" dense hint=""/>
                                    <q-input outlined v-model="model.email" label="Email" dense hint=""/>
                                    <q-input outlined v-model="model.info" label="Инфо" autogrow dense hint=""/>
                                    <q-btn v-if="this.id == null" :icon="copyIcon" color="white" text-color="black" @click="onCopy" label="Копировать в данные администратора?" />
                                </div>
                            </div>
                        </q-tab-panel>

                        <q-tab-panel name="admin">
                             <div class="q-pa-md">
                                <div class="column">
                                    <q-input outlined v-model="model.admin.name" label="ФИО" dense required hint=""/>
                                    <div class="row q-col-gutter-x-md">
                                        <q-input outlined v-model="model.admin.inn" label="ИНН" dense hint="" class="col-grow col-md-6"/>
                                        <q-input outlined v-model="model.admin.ogrn" label="ОГРН" dense hint="" class="col-grow col-md-6"/>
                                    </div>
                                    <q-input outlined v-model="model.admin.address" label="Адрес" dense hint=""/>
                                    <q-input outlined v-model="model.admin.phone" label="Телефон" dense hint=""/>
                                    <q-input outlined v-model="model.admin.email" label="Email" dense hint=""/>
                                    <q-input outlined v-model="model.admin.info" label="Инфо" autogrow dense hint=""/>
                                </div>
                            </div>
                        </q-tab-panel>

                      
                    </q-tab-panels>

                   
                    <q-card-section class="q-mt-none">
                        <q-btn type="submit" class="bg-primary text-white" label="Сохранить" />
                        <q-btn v-if="this.id != null" class="bg-red-5 text-white float-right" label="Удалить" @click="onDelete" />
                    </q-card-section>
                </q-form>
            </q-card>
        </q-dialog>
</template>

<script>

export default {
    props:['openDialog'],
    created(){
        this.$store.commit('violators/SET_EMPTY_ITEM')
    },
    data () {
        return {
            tab: 'user',
            copyIcon: ''
        }
    },
    computed:{
        model(){
            return this.$store.state.violators.item
        },
        id(){
            return this.$store.state.violators.updateId
        },
    },
    methods:{
        onDelete(){
            this.$store.dispatch('violators/deleteItem', this.id)
            this.$emit('close')
        },
        onSubmit(){
            if(this.id != null){
                this.$store.dispatch('violators/updateItem', {'id': this.id, 'item': this.model})
            }else{
                this.$store.dispatch('violators/addItem', this.model)
            }
            this.$emit('close')
        },
        onCopy(){
            this.$store.state.violators.item.admin = {...this.$store.state.violators.item}
            this.copyIcon = 'check'
            setTimeout(()=>{
                this.copyIcon = ''
            }, 5000)
        },
       
    }
    
}
</script>