<template>
    <div class="q-pa-md">
        <q-card class="my-card">
            <q-card-section>
                <div class="text-h6">{{title}}</div>
            </q-card-section>
            <q-separator />
            <q-form @submit="onSubmit" class="q-gutter-md">
            <q-card-section>
                <div class="q-gutter-y-md column">
                    <div class="row q-col-gutter-x-md">
                        <q-select dense outlined v-model="form.type" :options="types" label="Тип нарушения" class="col-grow col-md-4" :rules="[selectRule]"/>
                        <q-select
                            outlined
                            dense
                            v-model="form.author"
                            use-input
                            hide-selected
                            fill-input
                            input-debounce="0"
                            :options="authors"
                            @filter="filterAuthors"
                            label="Автор"
                            class="col-grow col-md-4"
                            :rules="[selectRule]"
                            emit-value
                            map-options
                            
                        >
                            <template v-slot:no-option>
                                <q-item>
                                    <q-item-section class="text-grey">
                                    Не надено
                                    </q-item-section>
                                </q-item>
                            </template>
                             <template v-slot:append>
                                <q-btn v-if=" form.author === null" round dense flat icon="add" @click.stop="openAuthorForm = true" />
                                <q-icon v-if="form.author !== null" class="cursor-pointer" name="clear"  @click.stop="form.author = null"  />
                            </template>
                        </q-select>
                            <q-select
                            outlined
                            dense
                            v-model="form.violator"
                            use-input
                            hide-selected
                            fill-input
                            input-debounce="0"
                            :options="violators"
                            @filter="filterViolators"
                            label="Нарушитель"
                            class="col-grow col-md-4"
                            :rules="[selectRule]"
                            emit-value
                            map-options
                        >
                            <template v-slot:no-option>
                                <q-item>
                                    <q-item-section class="text-grey">
                                    Не надено
                                    </q-item-section>
                                </q-item>
                            </template>
                             <template v-slot:append>
                                <q-btn v-if="form.violator === null" round dense flat icon="add" @click.stop="openViolatorForm = true" />
                                <q-icon v-if="form.violator !== null" class="cursor-pointer" name="clear"  @click.stop="form.violator = null"  />
                            </template>
                        </q-select>
                    </div>
                    <violation-objects :violationId="violationId"/>
                </div>
               
            </q-card-section>   
            <q-card-section class="q-mt-none">
                <q-btn type="submit" class="bg-primary text-white" label="Сохранить" />
            </q-card-section>
            </q-form>
        </q-card>
        <author-form :openDialog="openAuthorForm" @close="openAuthorForm = false"/>
        <violator-form :openDialog="openViolatorForm" @close="openViolatorForm = false"/>
    </div>
</template>

<script>
import ViolationObjects from '@/components/violations/objects.vue'
import AuthorForm from '@/components/authors/form.vue'
import ViolatorForm from '@/components/violators/form.vue'
export default {
  components: {  ViolationObjects, AuthorForm, ViolatorForm },
    data () {
        return {
            tab: 'info',
            title: "Изменить нарушение",
            form: {
                type: null,
                author:null,
                violator: null,
            },
            types: [{id:1, label:'Онлайн'}, {id:2, label:'Оффлайн'}],
            authors:[],
            violators:[],
            openAuthorForm: false,
            openViolatorForm: false,
        }
    },
    created(){
        if(!this.$route.params.id){
            this.title = "Создать нарушение"
            this.$store.commit('violations/EMPTY_OBJECTS')
        }else{
            this.$store.dispatch('violations/fetchItemById', this.$route.params.id)
            this.form.author = this.$store.state.violations.item.author || null
            this.form.violator = this.$store.state.violations.item.violator || null
            this.form.type = this.$store.state.violations.item.type || null
        }
    },
    computed:{
        violationId(){
            return this.$route.params.id || null
        },

    },
    methods: {
        async filterAuthors(val, update, abort){
                if (val.length < 2) {
                    abort()
                    return
                }
                this.authors = await this.$store.dispatch('authors/searchByName', val.toLowerCase())
                update()
                return
        },
        async filterViolators(val, update, abort){
                if (val.length < 2) {
                    abort()
                    return
                }
                this.violators = await this.$store.dispatch('violators/searchByName', val.toLowerCase())
                update()
                return
        },
        selectRule (val) {
            if (val === null) {
                return ''        
            }
        },
        onSubmit(){
            this.$store.commit('violations/ADD_VIOLATION',this.form)
            this.$router.push('/violations')
        },
      
    }
}
</script>