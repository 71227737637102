<template>
    <div>
        <q-table
        title="Объекты нарушения"
        :data="data"
        :columns="columns"
        row-key="number"
        flat
        bordered
        no-data-label="Нет данных"
        no-results-label="Нет данных"
        :rows-per-page-options="[20]"
        table-header-class="bg-grey-2"
        >
            <template v-slot:top-right>
                <!-- <q-btn outline color="secondary" label="Добавить" @click="createObject"/> -->
                <q-btn unelevated no-caps color="grey-1" text-color="green-9" icon="add" label="Добавить" @click="createObject"/>
            </template>
            <template v-slot:header-cell="props">
                <q-th :props="props">
                    <strong>{{ props.col.label }}</strong>
                </q-th>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props" style="cursor:pointer" @click="updateObject(props.rowIndex)">
                    <q-td key="num" :props="props" >
                        {{props.rowIndex + 1}}
                    </q-td>
                    <q-td key="url" :props="props" style="white-space: pre-line;word-break: break-word;">
                        {{props.row.pageUrl}}
                    </q-td>
                    <q-td key="type" :props="props">
                        {{props.row.type.label}}
                    </q-td>
                </q-tr>
            </template>
        </q-table>
        <object-form :data="formData" :openDialog="openDialog" @close="openDialog = false"/>
    </div>
</template>

<script>
import ObjectForm from './object_form.vue'

export default {
  components: { ObjectForm },
    props:['violationId'],
    created(){
        this.$store.dispatch('violations/fetchObjects', this.violationId)
    },
    data () {
        return {
            formData:{
                updateId: null,
                fields: {
                    pageUrl: null,
                    imageUrl:null,
                    contactsUrl:null,
                    photoBlogUrl: null,
                    photoBlogImageUrl:null,
                    blogYear:null,
                    domain:null,
                    type: null
                }
            },
            columns: [
                { name: 'num', required: true, label: '#', align: 'left', field: 'num'},
                { name: 'url', align: 'left', label: 'Url', field: 'url' },
                { name: 'type', align: 'left', label: 'Тип', field: 'type' },
            ],
            openDialog: false,
        }
    },
    computed:{
        data(){
            return this.$store.state.violations.objects || []
        }
    },
    methods: {
        createObject(){
            this.formData.updateId = null
            this.resetObject()
            this.openDialog =! this.openDialog
        },
        updateObject(row){
            this.formData.updateId = row
            this.formData.fields = this.$store.state.violations.objects[row]
            this.openDialog =! this.openDialog
        },
        resetObject() {
            let self = this;
            Object.keys(this.formData.fields).forEach(function(key) {
            self.formData.fields[key] = null;
            });
        }
    }
}
</script>