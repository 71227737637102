<template>
    <q-dialog full-width v-model="openDialog">
        <q-card>
            <q-card-section class="row items-center q-pb-none">
                <div class="text-h6">Объект</div>
                <q-space />
                <q-btn icon="close" flat round dense @click="$emit('close')" />
            </q-card-section>
                <q-form @submit="onSubmit" class="q-gutter-md" >
                    <div class="q-pa-md">
                        <div class="column">
                            <q-input outlined v-model="data.fields.pageUrl" label="Url страницы нарушения" dense required hint=""/>
                            <q-input outlined v-model="data.fields.imageUrl" label="Url фото нарушения" dense hint=""/>
                            <q-input outlined v-model="data.fields.contactsUrl" label="Url контактов нарушителя" dense hint=""/>
                            <q-input outlined v-model="data.fields.photoBlogUrl" label="Url фотоблога" dense hint=""/>
                            <q-input outlined v-model="data.fields.photoBlogImageUrl" label="Url фото из фотоблога" dense hint=""/>
                        </div>
                        <div class="column">
                            <div class="row q-col-gutter-x-md">
                                <q-input outlined v-model="data.fields.blogYear" label="Дата блог" class="col-grow col-md-2 " mask="####" dense required hint=""/>
                                <q-input outlined v-model="data.fields.domain" label="Домен" class="col-grow col-md-7" dense required hint=""/>
                                <q-select outlined v-model="data.fields.type" :options="types" label="Тип объекта" class="col-grow " dense :rules="[typeRule]"/>
                            </div>
                        </div>
                    </div>
                    <q-card-section>
                        <q-btn type="submit" class="bg-primary text-white" label="Сохранить" />
                        <q-btn v-if="this.data.updateId != null" class="bg-red-5 text-white float-right" label="Удалить" @click="onDelete" />
                    </q-card-section>
                </q-form>
            </q-card>
        </q-dialog>
</template>

<script>

export default {
    props:['data','openDialog'],
   
    data () {
        return {
            types: [
                'фотоизображение',
                'рисунок(изображение)',
                'товарный знак',
                'промышленный образец'
            ]
        }
    },
    methods:{
        onDelete(){
            this.$store.commit('violations/DELETE_OBJECT', {'objectId': this.data.updateId})
            this.$emit('close')
        },
        onSubmit(){
            if(this.data.updateId != null){
                this.$store.commit('violations/UPDATE_OBJECT', {'index': this.data.updateId, 'object': this.data.fields})
            }else{
                this.$store.commit('violations/ADD_OBJECT', {'object': this.data.fields})
            }
            this.$emit('close')
        },
        typeRule (val) {
            if (val === null) {
                return ''        
            }
        }
       
    }
    
}
</script>